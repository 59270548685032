<template>
    <div>
      <page-title :heading=heading :subheading=subheading></page-title>
      
      <!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">List of Users</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" @click="onCreateItem" class="">
						<a-icon type="plus" theme="outlined" class="text-white mr-0" />Add New 
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			

			<div class="mt-3">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="12">
						<a-select v-model="pagination.perPage" :options="pagination.perPageOptions" style="width: 70px"/>
						<label for="" class="ml-10">&nbsp;&nbsp;entries per page</label>
					</a-col>
					<a-col :span="24" :md="12" class="text-right">
                        <a-input-search
                            placeholder="search name..." 
							style="max-width: 250px;" 
							v-model="pagination.search"
                            @search="onSearchItem">
							<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
								<a-icon type="close" theme="outlined" class="text-error mr-3"  @click="onClearSearchItem" />
							</template>
						</a-input-search>
					</a-col>
				</a-row>
			</div>
			
			<div class="mt-5">
				<a-row type="flex" :gutter="24" class="pb-4">
					<a-col :span="24" :md="24">
						<a-table class="mt-20 pb-2"  
							size="middle"
							:bordered="false"
							:columns="columns" 
							:data-source="records" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="fullName" slot-scope="fullName, record">
									{{ `${record.firstName} ${record.lastName}` }}
								</template>

								<template slot="contacts" slot-scope="contacts, record">
									Phone: {{ record.phone }} <br/>
									Email: {{ record.email }}
								</template>

								<template slot="status" slot-scope="status, record">
									<a-badge  
										style="font-size: 12px !important; padding: 2px 5px;" 
										:count="record.isActive ? `Active` : `Inactive`" 
										:number-style="record.isActive ? { backgroundColor: '#52C41A', color: '#FFFFFF'} : { backgroundColor: '#F44335', color: '#FFFFFF'}"/>
								</template>

								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn text-success" :href="`/user-management/users/view/${record.uuid}`">
										<a-icon type="eye" /> View
									</a-button>
								</template>
							
						</a-table>
					</a-col>
					<a-col :span="12" class="mt-2 text-muted">
						<p class="category">Showing {{from + 1}} to {{to}} of {{pagination.total}} entries</p>
					</a-col>
					<a-col :span="12" class="text-right mt-2">
						<a-pagination 
							v-model="pagination.currentPage" 
							:pageSize="pagination.perPage"  
							:total="pagination.total" 
							@change="onCurrentPageChange"/>
					</a-col>
				</a-row>
			</div>
		</a-card>


		<!-- FORM MODAL -->
		<a-modal v-model="newItem.showModal" :footer="null">
			<h4 class="text-center">{{ newItem.isCreate ? 'Add User' : 'Edit User'}}</h4>
			<a-form
                :form="form"
                class="mb-0 pb-0"
                layout="vertical"
				@submit.prevent="handleSubmit">

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="First Name"
					:colon="false">
						<a-input 
							name="firstName"
							v-decorator="['firstName', { rules: [{ required: true, message: 'Please enter first name of the user!' }] }]"
							placeholder="First Name"
							/>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Last Name"
					:colon="false">
						<a-input 
							name="lastName"
							v-decorator="['lastName', { rules: [{ required: true, message: 'Please enter last name of the user!' }] }]"
							placeholder="Last Name"
							/>
				</a-form-item>


				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Phone Number"
					:colon="false">
						<a-input 
							name="phoneCopy"
							v-decorator="[
							'phoneCopy',
							{ rules: [{ required: true, message: 'Please enter user phone number!' }, {len: 10, message: 'Please enter user valid phone number'}, ] },
							]" placeholder="Eg: 0621314682" />
				</a-form-item>


				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Email Address"
					:colon="false">
						<a-input 
							v-decorator="[
							'email',
							{ rules: [{ required: true, message: 'Please enter user email address!' }] },
							]" placeholder="Eg: john@gmail.com" />
				</a-form-item>

				
				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Gender"
					placeholder="Select Gender"
					:colon="false">
					<a-select placeholder="Select Gender" 
						name="gender"
						v-decorator="[ 'gender',
						{ rules: [{ required: true, message: 'Please select gender of the user!' }] },]">
						<a-select-option v-for="option in genders"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item 
					class="my-0 mt-4 pb-0" 
					label="Role"
					placeholder="Select Role"
					:colon="false">
					<a-select placeholder="Select Role" 
						name="roleUuid"
						v-decorator="[ 'roleUuid',
						{ rules: [{ required: true, message: 'Please select role of the user!' }] },]">
						<a-select-option v-for="option in roles"
							:value="option.uuid"
							:key="option.name">
							{{ option.name }}
						</a-select-option>
					</a-select>
				</a-form-item>

				
				<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
					<a-button type="primary" color="#D15A1B" :loading="newItem.loading" html-type="submit" class="">
						{{ newItem.isCreate ? 'Create' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form>
			
		</a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'FULL NAME',
			dataIndex: 'fullName',
			scopedSlots: { customRender: 'fullName' },
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
			// width: "0%" 
		},
		{
			title: 'ROLE',
			dataIndex: 'role.name',
			scopedSlots: { customRender: 'role' },
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
			// width: "40%"
		},
		{
			title: 'CONTACTS',
			dataIndex: 'contacts',
			scopedSlots: { customRender: 'contacts' },
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
			// width: "40%"
		},
		{
			title: 'GENDER',
			dataIndex: 'gender',
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
			// width: "40%"
		},
		{
			title: 'STATUS',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' },
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
			// width: "40%"
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// align: "center"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
      components: {
        PageTitle,
      },
      data() {
			return {
				heading: 'Settings',
				subheading: 'Medicine Types',

				pagination: {
					search: '',
					loading: false,
					perPage: 10,
					currentPage: 1,
					perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
					total: 10
				},

				columns,
				message: '',

				records: [],
				roles: [],
				genders: [
					{ uuid: "Male", name: "Male" },
					{ uuid: "Female", name: "Female" },
				],
				
				form: this.$form.createForm(this, { name: 'newItemForm' }),
				rules,
				countryCode: '255',
				newItem: {
					uuid: null,
					name: null,
					companyUuid: null,
					codeName: null,
					description: null,
					isCreate: true,
					loading: false,
					showModal: false,
				},

				deleteDataItem: {
					uuid: null,
					showModal: false,
				},

				userDetails: {
					user: null,
					company: null
				},

				notify: {
					text: null,
					type: null,
				},
			}
		},
		computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getUsers();
                },
            },
            deep: true,
        },
		created() {
            this.getUserDetails();
			this.getUsers();
			this.getRoles();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.pagination.currentPage = 1;
					this.pagination.perPage = 10;
					
					this.getUsers()
				}else{
					this.notify.text = "Please enter something to search"
					this.notify.type = "error"

					this.$notify(this.notify)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getUsers();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getUsers();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getUsers();
            },


            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },

			async getUsers() {
                this.pagination.loading = true;

				let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                const { currentPage, perPage, search } = this.pagination;

                let url = `${this.$BACKEND_URL}/users/${userDetails.company.uuid}?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.records = response.data.data;
                            this.pagination.totalPages = response.data.totalPages;
                            this.pagination.total = response.data.total;
                            this.pagination.currentPage = response.data. currentPage;
                        }else{
                            this.notify.text = "There is no data available";
							this.notify.type = "success";
                            this.$notify(this.notify);
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
            },


			async getRoles() {

                const currentPage = 1;
				const perPage = 1000;

                let url = `${this.$BACKEND_URL}/roles?page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.roles = response.data.data;
                    }
					
                }).catch((error) => {
					
                    this.notify.text = error.response &&  error.response.data ? error.response.data.message : "Something went wrong, try again please!";
                    this.notify.type = "error"
                    this.$notify(this.notify)

                });
            },


			onCreateItem() {
                // this.newItem.uuid = null;
                this.newItem.companyUuid = this.userDetails.company.uuid;
				setTimeout(() => {
					this.form.setFieldsValue({
						firstName: "",
						lastName: "",
						email: "",
						phoneCopy: "",
						gender: "",
						roleUuid: "",
					});
				}, 10);
				this.newItem.isCreate = true;
				this.newItem.loading = false;
				this.newItem.showModal = true;
            },


			async handleSubmit() {

				this.form.validateFields((err, values) => {
					if ( !err ) {

						this.newItem.loading = true;
			
						let url = `${this.$BACKEND_URL}/auth/register`
						
						let payload = values;
						
						if(payload.phoneCopy.substring(0, 4).includes(this.countryCode) && !payload.phoneCopy.includes("+")) {
							payload.phone = "+" + payload.phoneCopy;
						}

						else if(payload.phoneCopy.substring(0, 4).includes(this.countryCode) && payload.phoneCopy.includes("+")) {
							payload.phone =  payload.phoneCopy;
						}

						else if(payload.phoneCopy[0] == "0" || payload.phoneCopy[0] == 0) {
							payload.phone = "+" + this.countryCode + "" + payload.phoneCopy.substring(1)
						}

						else if(payload.phoneCopy[0] != "0" || payload.phoneCopy[0] != 0) {
							payload.phone = "+" + this.countryCode + "" + payload.phoneCopy
						}

						else {
							payload.phone = payload.phoneCopy;
						}


						payload.companyUuid = this.userDetails.company.uuid;

						this.$AXIOS.post(url, payload).then(response => {

							if (response.status >= 200 && response.status < 210) {

								this.form.resetFields()

								this.newItem.uuid = null;
								this.newItem.name = null;
								this.newItem.codeName = null;
								this.newItem.description = null;
								this.newItem.isCreate = true;
								this.newItem.loading = false;
								this.newItem.showModal = false;

								this.notify.text = response.data.message
								this.notify.type = "success"

								this.$notify(this.notify)

							}

							this.getUsers()

						}).catch(error => {
						
							this.newItem.loading = false;
				
							if(error.response != null && error.response.status == 401) {
								this.$router.push(`/`);
							}

							this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
							this.notify.type = "error"

							this.$notify(this.notify)
				
						});

					}
				})
				
			},

		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>